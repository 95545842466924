import { isDemo, isDevEnv, isLocal } from "./utils/constants";

const trackUrl = "https://misc.ommej.se/umami/script.js";
const devId = "7c937f28-5be4-44a9-8330-a9428fcdb39c";
const demoId = "2a59228f-a830-4b7e-baa9-cf205c2025f5";
const prodId = "d7c64150-b263-493f-86bc-d61c86188404";

const createScriptTag = (environmentId: string) => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("defer", "");
    script.setAttribute("async", "");
    script.dataset.websiteId = environmentId;
    script.dataset.doNotTrack = "true";
    script.setAttribute("src", trackUrl);
    if (head) {
        head.appendChild(script);
    }
};

const loadTrackerScript = () => {
    if (isLocal) {
        return;
    }
    if (isDevEnv) {
        createScriptTag(devId);
    } else if (isDemo) {
        createScriptTag(demoId);
    } else {
        createScriptTag(prodId);
    }
};

loadTrackerScript();
